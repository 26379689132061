import { Category, CategoryService, SortOrder } from '../../../services/category-service';

export const getSettingsCategory = async ({
  categoryService,
  compId,
  targetCategoryId,
}: {
  compId: string;
  categoryService: CategoryService;
  targetCategoryId: string | undefined;
}): Promise<Category | undefined> => {
  let category: Category | undefined;

  if (targetCategoryId) {
    category = await categoryService.getCategory(targetCategoryId);
  } else {
    const categories = await categoryService.queryCategories({
      query: { sort: [{ fieldName: 'createdDate', order: SortOrder.ASC }] },
    });

    category =
      categories.filter((category) => category.id === targetCategoryId)[0] ?? categories[0];
  }

  if (!category) {
    throw new Error(`Settings category for ${compId} not found}`);
  }
  return category;
};
