import { WidgetStore } from './controller/widget-store';

interface IDefaultPluginInterface {
  /** @see https://github.com/wix-private/editor-platform/blob/master/packages/widget-plugins-interfaces/src/interfaces/default.ts */
  set resourceId(resourceId: string);
}

export const createPublicApi = (widgetStore: WidgetStore): IDefaultPluginInterface & any => {
  return {
    set resourceId(resourceId: string) {
      widgetStore.setState({ resourceId });
    },
    setResourceId(resourceId: string) {
      widgetStore.setState({ resourceId });
    },
    setIsLocked(isLocked: boolean) {
      widgetStore.setState({ isLocked });
    },
  };
};
