import { ControllerFlowAPI } from '@wix/yoshi-flow-editor';
import { registerCommentsWidget } from '@wix/ambassador-comments-categories-v1-comments-widget/http';
import { isPageDynamic as getIsPageDynamic } from './is-page-dynamic';
import { getDynamicSection } from './get-dynamic-section';
import { resolveWidgetPath } from './resolve-widget-path';

export const registerWidget = async (
  { environment, httpClient, controllerConfig, errorMonitor, experiments }: ControllerFlowAPI,
  { resourceId, categoryId, pageId }: { resourceId?: string; pageId?: string; categoryId?: string },
) => {
  let urlPath: string | undefined;

  if (!resourceId || !categoryId || environment.isEditor || environment.isPreview) {
    return;
  }

  const { wixCodeApi, compId } = controllerConfig;
  const isPageDynamic = await getIsPageDynamic(wixCodeApi);

  try {
    urlPath = experiments.enabled('specs.wixComments.sendUrlOnWidgetRegister')
      ? resolveWidgetPath(wixCodeApi)
      : undefined;
  } catch (e) {
    if (e instanceof Error) {
      errorMonitor.captureException(e, { tags: { context: 'registerWidget' } });
    }
  }

  try {
    await httpClient.request(
      registerCommentsWidget({
        resourceId,
        categoryId,
        componentId: compId,
        pageId,
        ...(isPageDynamic ? { dynamicPageTitle: getDynamicSection(wixCodeApi.location) } : {}),
        urlPath,
      }),
    );
  } catch (e) {
    if (e instanceof Error) {
      errorMonitor.captureException(e, { tags: { context: 'registerWidget' } });
    }
  }
};
